import React from "react";
import { Request } from "../classes/Request";
import { NewClassRequest } from "../classes/NewClassRequest";
import { NotificationManager } from "react-notifications";
import {Button, Modal} from "react-bootstrap";

export const initForm = {
  password: "",
  confirm_password: "",
  email: "",
  regime: "",
  user_type: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
// ###REFACTORIZADA
export const register = async (form, recaptcha, setErrors) => {
  if (recaptcha && recaptcha.trim().length > 0) {
    const request = new NewClassRequest(
      `${process.env.REACT_APP_URL_API_LARAVEL}/auth`,
      "post",
      null,
      {
        app_id: 2,
        active: 0,
        ...form  
      }
    );
    const validateRegister = await request.executeRequest();
    
    if ( validateRegister.code !== 200 ) {

      if ( 
        validateRegister.response.message && 
        typeof validateRegister.response.message ===  "object" 
        ) {

          setErrors( validateRegister.response.message );
          NotificationManager.error( "Existen errores que impiden continuar, revisa los campos marcados en rojo." );
        }
      return false
    }
    else if ( validateRegister.code === 200 ) {

      if ( validateRegister.response.error ) {

        setErrors( validateRegister.response.message );
        NotificationManager.error( "Existen errores que impiden continuar, revisa los campos marcados en rojo." );
        return false
      }
      else {
        return validateRegister.response.message
      }
    }
  } else {
    const errors = {
      recaptcha:["Este campo es obligatorio"]
    }
    setErrors(errors);
    return false
  } 
};

/**********************************************************FORGOT PASSWORD************************************************************************/
/**
 * Function to get terms and conditions of use.
 * @returns {Promise<{code: *, message: *}|any>}
 */
export const termsRequest = async (setTermsTitle, setTermsText, setCanShowModal) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/terms/conditions?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const termsRequestData = await request.executeRequest(false);
  if (termsRequestData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTermsTitle(termsRequestData.data.data.data.content.title);
    setTermsText(termsRequestData.data.data.data.content.description);
  }
  else {
    setCanShowModal(false);
  }
};
/***************************************************************************************************************************************************/

export const ModalTermsAndConditions = (show,setHandleTermsModal, termsText, termsTitle) => {

  return (
    <>
      <Modal 
        show={show}
        onHide={()=> {
          setHandleTermsModal(false);
        }}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{termsTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{__html: termsText}}
            style={{maxHeight: "60vh", overflowY: "scroll"}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="primary" 
            onClick={() => {
              setHandleTermsModal(false);
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const getMunicipalities = async (setMunicipalities) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/municipalities`,
    "get",
    null,
    {}
  );
  const responseMunicipalities = await request.executeRequest(false);
  if (
    responseMunicipalities &&
    responseMunicipalities.code === 200 &&
    responseMunicipalities.response &&
    responseMunicipalities.response.municipalities &&
    responseMunicipalities.response.municipalities.length > 0
  ) {
    setMunicipalities(responseMunicipalities.response.municipalities);
  }
};

export const getCountries = async (setCountries) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/countries`,
    "get",
    null,
    {}
  );
  const responseCountries = await request.executeRequest(false);
  
  if (
    responseCountries &&
    responseCountries.code === 200 &&
    responseCountries.response &&
    responseCountries.response.countries &&
    responseCountries.response.countries.length > 0
  ) {
    setCountries(responseCountries.response.countries);
  }
}

export const getStates = async (setStates, country_id) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/states/${country_id}`,
    "get",
    null,
    {}
  );
  const responseStates = await request.executeRequest(false);
  if (
    responseStates &&
    responseStates.code === 200 &&
    responseStates.response &&
    responseStates.response.states &&
    responseStates.response.states.length > 0
  ) {
    setStates(responseStates.response.states);
  }
}

export const getCities = async (setCities, state_id) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/catalogs/cities/${state_id}`,
    "get",
    null,
    {}
  );
  const responseCities = await request.executeRequest(false);
  if (
    responseCities &&
    responseCities.code === 200 &&
    responseCities.response &&
    responseCities.response.cities &&
    responseCities.response.cities.length > 0
  ) {
    setCities(responseCities.response.cities);
  }
}