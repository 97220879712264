import React from 'react';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import { range } from 'lodash';
import { NewClassRequest } from '../classes/NewClassRequest';
import { NotificationManager } from 'react-notifications';

const years = range(1800, 2040);
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

export const secondCustomHeaderDatePicker = (
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
) => {

  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault();
          decreaseMonth();
        }}
        disabled={prevMonthButtonDisabled}
        style={{ border: '2px solid #216ba5', borderRadius: '5px', backgroundColor: '#216ba5', fontSize: '10px' }}
      >
        <i className="fas fa-arrow-left" style={{ color: 'white', fontSize: '8px' }} title="Last year" />
      </button>
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) =>
          changeMonth(months.indexOf(value))
        }
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={(e) => {
          e.preventDefault();
          increaseMonth();
        }}
        disabled={nextMonthButtonDisabled}
        style={{ border: '2px solid #216ba5', borderRadius: '5px', backgroundColor: '#216ba5', fontSize: '10px' }}
      >
        <i className="fas fa-arrow-right" style={{ color: 'white', fontSize: '8px' }} title="Next year" />
      </button>
    </div>
  )
};

export const getExpedientsByDate = async (
  courtId, year, month, day,
  setData, setMessage, setDataFiltered,
  handleRequestDetailExpedient, setCourtData
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/by_date/${courtId}/${year}/${month + 1}/${day}`,
    'get',
    null,
    {}
  );
  const responseExpedientSearch = await request.executeRequest();
  if (
    responseExpedientSearch.code === 200 &&
    responseExpedientSearch.response &&
    responseExpedientSearch.response.Expedients &&
    responseExpedientSearch.response.Expedients.length > 0
  ) {
    setMessage('');
    const newData = formattedData(
      responseExpedientSearch.response.Expedients,
      handleRequestDetailExpedient
    );
    setCourtData(responseExpedientSearch.response.court_data);
    setData(newData);
    setDataFiltered(newData);
  } else if (
    responseExpedientSearch.code === 404 &&
    responseExpedientSearch.response.error &&
    responseExpedientSearch.response.message
  ) {
    setData([]);
    setDataFiltered([]);
    setMessage(responseExpedientSearch.response.message);
    NotificationManager.error(
      responseExpedientSearch.response.message,
      '', 4500
    );
  } else {
    setData([]);
    setDataFiltered([]);
    NotificationManager.error(
      'Ha ocurrido un error inesperado y la búsqueda no pudo ser completada',
      '', 4500
    );
  }
};

const formattedData = (data, handleRequestDetailExpedient) => {
  const expedients = data.map((exp, i) => {
    exp['defendant'] = exp['defendant'] || 'N/A';
    exp['allText'] = exp['actor'] + ' ' + exp['expedient'] + ' ' + exp['judgement_type'] + ' ' +
      exp['via'] + ' ' + exp['defendant'];
    exp['expedient_link'] = (<p
      style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => handleRequestDetailExpedient(exp.expedient)}
    >
      {exp['expedient']}
    </p>);

    return exp;
  });

  return expedients;
};

export const columnsByDate = [
  {
    dataField: "id",
    text: "Id",
    style: { display: "none" },
    headerStyle: { display: 'none' }
  },
  {
    dataField: "expedient_link",
    text: "No. de expediente",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "actor",
    text: "Actor",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "defendant",
    text: "Demandado",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "judgement_type",
    text: "Tipo de juicio",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "via",
    text: "Vía",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  }
];

export const columnsByNumber = [
  {
    dataField: "id",
    text: "Id",
    style: { display: "none" },
    headerStyle: { display: 'none' }
  },
  {
    dataField: "expedient",
    text: "No. de expediente",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "actor",
    text: "Actor",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "defendant",
    text: "Demandado",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "judgement_type",
    text: "Tipo de juicio",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  },
  {
    dataField: "via",
    text: "Vía",
    style: { textAlign: "center" },
    headerStyle: { width: "20%", textAlign: "center" }
  }
];

export const getExpedientsBySearchMethod = async (setData, setMessage, setDataFiltered, handleRequestDetailExpedient, url, method) => {
  const request = new NewClassRequest(
    url,
    'get',
    null,
    {}
  );
  const responseExpedientSearch = await request.executeRequest();
  console.log(method);
  if (
    responseExpedientSearch.code === 200 &&
    responseExpedientSearch.response &&
    responseExpedientSearch.response.Expedients &&
    responseExpedientSearch.response.Expedients.length > 0
  ) {
    setMessage('');
      const data = responseExpedientSearch.response.Expedients
        .map((exp, i) => ({
          expedient: (
              <p
                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => handleRequestDetailExpedient(exp.expedient)}
              >
                {'Expediente: ' + exp.expedient}
              </p>),
          allText: exp.expedient,
          actor: exp.parties.actor ? exp.parties.actor.full_name : 'N/A',
          defendant: exp.parties.defendant ? exp.parties.defendant.full_name : 'N/A',
          judgement_type: exp.judgement_type,
          via: exp.via
        }));
      setData(data);
      setDataFiltered(data);
  } else if (
    responseExpedientSearch.code === 404 &&
    responseExpedientSearch.response.error &&
    responseExpedientSearch.response.message
  ) {
    setData([]);
    setDataFiltered([]);
    setMessage(responseExpedientSearch.response.message);
    NotificationManager.error(
      responseExpedientSearch.response.message,
      '', 4500
    );
  } else {
    setData([]);
    setDataFiltered([]);
    NotificationManager.error(
      'Ha ocurrido un error inesperado y la búsqueda no pudo ser completada',
      '', 4500
    );
  }
}

export const getDocumentsByExpedient = async (
  courtId,
  expedientNumber,
  setDocuments,
  setShow,
  setShowCommentModal,
  setCommentModal
) => {
  setDocuments({
    agreements: [],
    judgments: []
  });

  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find/documents/${expedientNumber}/${courtId}`,
    'get',
    null,
    {}
  );

  const responseAgreements = await request.executeRequest();

  if (
    responseAgreements.code === 200 &&
    responseAgreements.response &&
    responseAgreements.response.expedient &&
    responseAgreements.response.expedient.length > 0
  ) {
    const expedient = responseAgreements.response.expedient[0];
    setDocuments({
      agreements: expedient['agreements'].map((a) => ({
        ...a,
        promo_date: a.promotion_date || 'N/A',
        comment_text: !a.comment ? 'N/A' : formatComment(a, setCommentModal, setShowCommentModal, 'Síntesis de Acuerdo'),
        download: (<a className="btn btn-success" href={a.filepath} target="_blank" rel="noopener noreferrer"><i className={"far fa-file"} /></a>)
      })),
      judgments: expedient['judgments'].map((judgment) => ({
        ...judgment,
        download: (<a className="btn btn-success" href={judgment.filepath} target="_blank" rel="noopener noreferrer"><i className={"far fa-file"} /></a>),
        comment_text: !judgment.comment ? 'N/A' : formatComment(judgment, setCommentModal, setShowCommentModal, 'Síntesis de la Sentencia'),
      }))
    });
    setShow(true);
  } else {
    NotificationManager.error(
      'No fue posible traer la información solicitada',
      '',
      4500
    );
  }
};

const formatComment = (documentExpedient, setComment, setShowModal, commentTitle) => {
  return (
    <div>
      {documentExpedient.comment.length <= 110
        ? <div>{documentExpedient.comment}</div>
        : <div>
          {documentExpedient.comment.slice(0, 110)}...
          <strong
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setComment({ title: commentTitle, comment: documentExpedient.comment });
              setShowModal(true);
            }}
          >Ver más
          </strong>
        </div>
      }
    </div>
  );
}

export const columnsAgreementsShow = [
  {
    dataField: "id",
    text: "Id",
    style: { display: "none" },
    headerStyle: { display: 'none' }
  },
  {
    dataField: "created_at",
    text: "Fecha de Acuerdo",
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "publication_date",
    text: "Fecha de Publicación",
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "promo_date",
    text: "Fecha de promoción",
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }

  },
  {
    dataField: "alias",
    text: "Rubro de Acuerdo",
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment_text",
    text: "Síntesis de Acuerdo",
    style: { textAlign: "center", maxWidth: "200px" },
    headerStyle: { textAlign: "center" }
  },
  // {
  //   dataField: "download",
  //   text: "Descargar",
  //   style: { textAlign: "center", maxWidth:"200px" },
  //   headerStyle: { textAlign: "center" }
  // }
];

export const columnsJudgmentsShow = [
  {
    dataField: "id",
    text: "Id",
    style: { display: "none" },
    headerStyle: { display: 'none' }
  },
  {
    dataField: "created_at",
    text: "Fecha de Sentencia",
    style: { textAlign: "center" },
    headerStyle: { textAlign: "center" }
  },
  {
    dataField: "comment_text",
    text: "Comentarios",
    style: { textAlign: "center", maxWidth: "200px" },
    headerStyle: { textAlign: "center" }

  },
  {
    dataField: "download",
    text: "Descargar",
    style: { textAlign: "center", maxWidth: "200px" },
    headerStyle: { textAlign: "center" }
  }
];
