import React, { useState, useRef} from 'react';
import { 
  Card, Col, Container, Row, 
  Button, FormLabel, FormControl, Modal } from 'react-bootstrap';
import Plain from '../layout/containers/Plain';
import Select from 'react-select';
import {
  getJudicialParties,
  getCourtsByJudicialPartyId,
  renderErrorsByInputName,
  fieldsValidation
} from '../search_for_lawyers/search_lawyers_logic';
import {
  secondCustomHeaderDatePicker, getExpedientsByDate, 
  columnsByDate, 
  columnsByNumber, 
  getExpedientsBySearchMethod,
  getDocumentsByExpedient,
  columnsAgreementsShow,
  columnsJudgmentsShow
} from './public_movements_logic';
import CustomInput from './CustomInputDate';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { NotificationManager } from 'react-notifications';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { backgroundImageStyles } from '../../inLineStyles/backgroundImageStyles/backgroundImageStyles';
moment.locale('es');
registerLocale('es', es);

const PublicMovements = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [judicialParties, setJudicialParties] = useState([]);
  const [courts, setCourts] = useState([]);
  const [search, setSearch] = useState({});
  const firstInputDatePicker = useRef(null);
  const [messageExistsResult, setMessageExistsResult] = useState('');
  const [data, setData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState({ agreements: [], judgments: [] });
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentModal, setCommentModal] = useState({});
  const [expedientToSee, setExpedientToSee] = useState('');
  const [courtData, setCourtData] = useState(null);

  const selectJudicialParty = async (selected) => {
    await getCourtsByJudicialPartyId(selected['value'], setCourts);
    setSearch({
      ...search,
      court: null,
      judicialParty: selected
    });
  };   
  
  const executeSearch = async () => {

    let requiredFields = ['judicialParty', 'court', 'method'];

    const requiredFieldsBySearchMethod = {
      byDate: ['dateSelected'],
      byExpedient: ['expedientNumber'],
      byPartiesName: ['partiesName'],
    }

    const additionalRequiredFields = requiredFieldsBySearchMethod[search.method];

    requiredFields=[...requiredFields, ...additionalRequiredFields];

    const responseOfValidation = fieldsValidation(
      requiredFields,
      search
    );
    if (typeof responseOfValidation === 'object') {
      setData([]);
      setDataFiltered([]);
      setErrors(responseOfValidation);
      NotificationManager.error(
        'Existen errores que te impiden continuar,' +
        ' por favor revisa los campos marcados en rojo'
      );
    } else {
      setSearching(true);
      setErrors({});
      const courtId = search['court']['value'];
      if (search['method'] === 'byDate') {
        const date = new Date(search['dateSelected']);
        await getExpedientsByDate(
          courtId, date.getFullYear(), 
          date.getMonth(), date.getDate(),
          setData, setMessageExistsResult, setDataFiltered,
          handleRequestDetailExpedient, setCourtData
        );
      } else if (isASearchByExpedient || isASearchByPartiesName) {
        setDataFiltered([]);
        const path = `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients`;
        
        const searchValueBySearchMethod = {
          byExpedient: search.expedientNumber.replace(/\//g, '-'),
          byPartiesName: search.partiesName,
        }
        
        const serachValue = searchValueBySearchMethod[search.method];
        const urlBySearchMethod = {
          byPartiesName: `${path}/by_names/${courtId}/${serachValue}`,
          byExpedient: `${path}/find/${serachValue}/${courtId}`,
        }
        
        const url = urlBySearchMethod[search.method];

        await getExpedientsBySearchMethod(
          setData,
          setMessageExistsResult,
          setDataFiltered,
          handleRequestDetailExpedient,
          url,
          search.method,
        );
      }
      setSearching(false);
    }
  };

  const executeClean = async () => {
    setCleaning(true);
    setTimeout(() => {
      setCourtData(null);
      setSearch({});
      setData([]);
      setCleaning(false);
    }, 500);
  };

  const filterData = (e) => {
    if (e.target.value.length >= 3) {
      const filtered = data.filter((item) => {
        if (
          item['allText'].toUpperCase().includes(e.target.value.toUpperCase())
        ) return item;
      });
      setDataFiltered(filtered);
    } else setDataFiltered(data);
  };

  const handleRequestDetailExpedient = async (
    expedientNumber
  ) => {
    setExpedientToSee(expedientNumber);
    await getDocumentsByExpedient(
      search['court']['value'],
      expedientNumber.replace(/\//g,'-'),
      setDocuments,
      setShow,
      setShowCommentModal,
      setCommentModal
    );
  };

  const onMethodRadioChange = ({ target } = {}) => {
    delete errors[target.name];
    setData([]);
    setDataFiltered([]);
    setSearch({
      ...search,
      [target.name]: target.value,
      expedientNumber: '',
      dateSelected: null,
      partiesName: '',
    });
  }

  const onMethodTextChange = ({ target } = {}) => {
    delete errors[target.name];
    setSearch({ ...search, [target.name]: target.value });
  }

  const isASearchByExpedient = search.method === 'byExpedient';
  const isASearchByPartiesName = search.method === 'byPartiesName';

  return (
    <>
      <Plain {...props} noHeader={true} noFooter={true}>
        <Container fluid={true} className='min-height-100vh bg-size-cover'
          style={backgroundImageStyles}>
          <Container>
            <Row style={{ minHeight: '100vh' }}>
              <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}
                className='mY-30'>
                <Card className='mY-40@sm+'>
                  <Card.Header>
                    <Container>
                      <Row>
                        <Col xs={12} md={{ span: 10, offset: 1 }} >
                          <h3 className='mb-0 text-center'>Boletín judicial</h3>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  <Card.Body>
                    <Container>
                      <Row className='mB-20'>
                        <div className='col-md-10 col-md-offset-1 text-justify h5'>
                          <h5 className='text-center'><b>INSTRUCCIONES</b></h5>
                          Para localizar los extractos relacionados a un expediente, selecciona el partido judicial
                          y selecciona a continuación el Juzgado correspondiente.
                        </div>
                      </Row>

                      <Row>
                        <Col sm className='mT-10'>
                          <FormLabel>
                            <b>Partido Judicial</b>
                          </FormLabel>
                          <Select
                            options={judicialParties}
                            onChange={selected => {
                              delete errors['judicialParty'];
                              selectJudicialParty(selected);
                            }}
                            placeholder='Selecciona un partido judicial'
                            value={search && search['judicialParty'] ? search['judicialParty'] : ''}
                            onFocus={async e => getJudicialParties(setJudicialParties, setIsLoading)}
                            isLoading={isLoading}
                            loadingMessage={() => 'Cargando partidos judiciales...'}
                            noOptionsMessage={() => 'No hay partidos judiciales disponibles'}
                          />
                          {renderErrorsByInputName(errors, 'judicialParty')}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm className='mT-10'>
                          <FormLabel>
                            <b>Juzgado o Sala</b>
                          </FormLabel>
                          <Select
                            options={courts}
                            onChange={selected => {
                              delete errors['court'];
                              setSearch({ ...search, court: selected });
                            }}
                            placeholder='Selecciona un juzgado'
                            value={search && search['court'] ? search['court'] : ''}
                            noOptionsMessage={search['judicialParty'] ? () => 'No hay juzgados disponibles' : () => 'Por favor, antes selecciona algún partido judicial'}
                          />
                          {renderErrorsByInputName(errors, 'court')}
                        </Col>
                      </Row>

                      <Row className='mT-40'>
                        <p className='col-md-10 col-md-offset-1 text-justify h6'>
                          El Consejo de la Judicatura de Jalisco pone a tu disposición dos alternativas para localizar información relacionada
                          a un expediente. La primera es por número de expediente y la segunda a partir de una fecha determinada, por favor
                          selecciona la opción deseada.
                        </p>
                      </Row>

                      <Row className='mT-10'>
                        <Col sm className='mT-10 ta-c col-md-4'>
                          <div className='form-check'>
                            <input
                              name={'method'}
                              value={'byDate'}
                              className={'form-check-input'}
                              type='radio'
                              id={'by-date-radio'}
                              checked={search['method'] === 'byDate'}
                              onChange={onMethodRadioChange}
                            />
                            <label
                              className={'form-check-label'}
                              htmlFor={'by-date-radio'}
                            >
                              Por fecha
                            </label>
                          </div>
                          {renderErrorsByInputName(errors, 'method')}
                        </Col>
                        <Col sm className='mT-10 ta-c col-md-4'>
                          <div className='form-check'>
                            <input
                              name={'method'}
                              value={'byPartiesName'}
                              className={'form-check-input'}
                              type={'radio'}
                              id={'by-parties-name-radio'}
                              checked={isASearchByPartiesName}
                              onChange={onMethodRadioChange}
                            />
                            <label
                              className={'form-check-label'}
                              htmlFor={'by-parties-name-radio'}
                            >
                              Por nombre de las partes
                            </label>
                          </div>
                          {renderErrorsByInputName(errors, 'method')}
                        </Col>
                        <Col sm className='mT-10 ta-c col-md-4'>
                          <div className='form-check'>
                            <input
                              name={'method'}
                              value={'byExpedient'}
                              className={'form-check-input'}
                              type='radio'
                              id={'by-expedient-radio'}
                              checked={isASearchByExpedient}
                              onChange={onMethodRadioChange}
                            />
                            <label
                              className={'form-check-label'}
                              htmlFor={'by-expedient-radio'}
                            >
                              Por expediente o TOCA
                            </label>
                          </div>
                          {renderErrorsByInputName(errors, 'method')}
                        </Col>
                      </Row>

                      {search['method'] ?
                        <Row className='mT-20'>
                          {
                            isASearchByExpedient ?
                              <>
                                <Col xs={12} className='mT-10 col-md-6 col-md-offset-3'>
                                  <FormLabel>
                                    <b>No. expediente</b>
                                  </FormLabel>
                                  <FormControl
                                    name={'expedientNumber'}
                                    placeholder='Ingresa el número de expediente...'
                                    aria-label='Ingresa el número de expediente...'
                                    aria-describedby='expedient-number-search'
                                    value={search['expedientNumber'] || ''}
                                    onChange={onMethodTextChange}
                                  />
                                  {renderErrorsByInputName(errors, 'expedientNumber')}
                                </Col>
                              </> : search['method'] === 'byDate' ?
                                <>
                                  <Col xs={12} className='mT-10 col-md-6 col-md-offset-3'>
                                    <FormLabel>
                                      <b>Fecha</b>
                                    </FormLabel>
                                    <DatePicker
                                      renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                      }) => secondCustomHeaderDatePicker(
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled
                                      )}
                                      locale='es'
                                      selected={search['dateSelected'] ? moment(search['dateSelected']).toDate() : ''}
                                      onChange={date => onMethodTextChange({ target: { value: date, name: 'dateSelected' } })}
                                      customInput={<CustomInput ref={firstInputDatePicker} />}
                                    />
                                    {renderErrorsByInputName(errors, 'dateSelected')}
                                  </Col>
                                </> : ''
                          }
                          {
                            isASearchByPartiesName
                            &&
                            <Col xs={12} className='mT-10 col-md-6 col-md-offset-3'>
                              <FormLabel>
                                <b>Nombre de las partes</b>
                              </FormLabel>
                              <FormControl
                                name={'partiesName'}
                                placeholder='Ingresa el nombre de las partes...'
                                value={search['partiesName'] || ''}
                                onChange={onMethodTextChange}
                              />
                              {renderErrorsByInputName(errors, 'partiesName')}
                            </Col>
                          }
                        </Row> : ''
                      }
                      <Row className='mT-30 justify-content-md-center'>
                        <Col></Col>
                        <Col></Col>
                        <Col md='auto' className='mT-10'>
                          <Button
                            variant='secondary'
                            className='search-expedient-public'
                            onClick={() => executeClean()}
                            disabled={ cleaning || searching }
                            style={ searching ? {display:"none"} : null}
                          >
                            { cleaning ? "Limpiando..." : "Nueva búsqueda"}
                          </Button>
                          <Button
                            variant='primary'
                            className='search-expedient-public'
                            onClick={() => executeSearch()}
                            disabled={ searching || cleaning }
                            style={ cleaning ? {display:"none"} : null}
                          >
                            { searching ? "Buscando..." : "Buscar "}
                          </Button>
                        </Col>
                      </Row>
                    </Container>

                    {data.length > 0 && messageExistsResult === '' ?
                      <Container>
                        <Row
                          style={
                            (isASearchByExpedient || isASearchByPartiesName) ?
                            {display:'flex', justifyContent:'center'} :
                            null
                          }
                        >
                          <Col
                            xs={12}
                            sm={6}
                          >
                            <FormControl
                              type='text'
                              style={{width:'100%', marginTop:'10px'}}
                              placeholder='Filtra los resultados aquí'
                              onChange={(e) => filterData(e)}
                            />
                          </Col>
                        </Row>
                        <Row
                          style={
                            (isASearchByExpedient || isASearchByPartiesName) ?
                            {display:'flex', justifyContent:'center'} :
                            null
                          }
                        >
                          <Col
                            xs={12}
                            sm={search['method'] === 'byDate' ? 12 : search['method'] === 'byExpedient' ? 'auto' : 12}
                            className="procedures-table-container"
                          >
                            <div
                              className="ov-s"
                              style={
                                search['method'] === 'byDate' ?
                                {maxHeight:'700px'} :
                                {maxHeight:'auto'}}
                            >
                              <BootstrapTable
                                remote
                                keyField='id'
                                data={dataFiltered}
                                columns={search['method'] === 'byDate' ? columnsByDate : columnsByNumber}
                              />
                            </div>

                            {search['method'] === 'byDate' && 
                            courtData && courtData['address'] && courtData['secretary'] ?
                            <div>
                              <span className='fsz-lg'><b>Secretario de Acuerdos:</b></span><br/>
                              <span className='fsz-md'>{courtData['secretary']}</span><br/>
                              <span className='fsz-md'>{courtData['address']}</span>
                            </div> : ''}
                          </Col>
                        </Row>
                      </Container> : <h5 className="text-center">{messageExistsResult}</h5>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </Plain>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-bulletin_judicial"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {expedientToSee}
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
          <Row>
            <Col
              xs={12}
              className="procedures-table-container"
            >
              <div className="text-center">
                <h4><strong>Acuerdos</strong></h4>
              </div>
              {documents.agreements.length === 0 ?
              <h4 className='ta-c'>No existe información para mostrar</h4> :
              <div className="ov-s" style={{maxHeight:'650px'}}>
                <BootstrapTable
                  remote
                  keyField='id'
                  data={documents.agreements}
                  columns={columnsAgreementsShow}
                />
              </div>}
              <hr/>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              className="procedures-table-container"
            >
              <div className="text-center">
                <h4><strong>Sentencias</strong></h4>
              </div>
              {documents.judgments.length === 0 ?
              <h4 className='ta-c'>No existe información para mostrar</h4> :
              <div className="ov-s" style={{maxHeight:'650px'}}>
                <BootstrapTable
                  remote
                  keyField='id'
                  data={documents.judgments}
                  columns={columnsJudgmentsShow}
                />
              </div>}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShow(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
        dialogClassName="modal-bulletin_judicial"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {commentModal.title}
          </Modal.Title>
        </Modal.Header>
          <Modal.Body>
          <Row>
            <Col>
              <div style={{ overflowY: 'auto', maxHeight: '65vh' }}>
                <div className="scroll">
                  {commentModal.comment}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShowCommentModal(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicMovements;
