import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import GroupButtonsForm from "../helpers_components/GroupSubmitFormButtons";
import { register, initForm, termsRequest, ModalTermsAndConditions, getMunicipalities, getCountries , getStates, getCities} from "./register_logic";
import GoogleRecaptcha from "../helpers_components/GoogleRecaptcha";
import TextTooltip from "../helpers_components/text_tooltip/TextTooltip";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import { renderErrorsList, renderClassesToErrors } from "../confirm_registration/confirm_registration_logic";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import CustomInput from "../search_to_citizens/CustomInputDate";
import { secondCustomHeaderDatePicker } from "../search_to_citizens/public_movements_logic";
import TextAlertModal from "../modals/TextAlertModal";
import { backgroundImageStyles } from "../../inLineStyles/backgroundImageStyles/backgroundImageStyles";
import Select from "react-select";
import { get } from "lodash";

moment.locale("es");
registerLocale("es", es);

const Register = props => {
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);
  const [recaptcha, setRecaptcha] = useState("");
  const [handleTermsModal, setHandleTermsModal] = useState(false);
  const [termsText, setTermsText] = useState(false);
  const [termsTitle, setTermsTitle] = useState("");
  const [canShowModal, setCanShowModal] = useState(true);
  const [municipalities, setMunicipalities] = useState([]);
  const [errors, setErrors] = useState({});
  const [textAlertRegistrationModal, setTextAlertRegistrationModal] = useState('');
  const [isRegistrationModalShowing, setIsRegistrationModalShowing] = useState(false);
  const firstInputDatePicker = useRef(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const genders = [
    {
      label: 'Hombre',
      value: 'male'
    },
    {
      label: 'Mujer',
      value: 'female'
    },
    {
      label: 'Género no binario',
      value: 'no_binary'
    }
  ]

  const selectStylesDefault = {
    placeholder: (provided, state) => ({
      ...provided,
      color: '#c1c1c1'
    }),
    control: (provided, state) => {
      if (state.isDisabled) {
        return {
          ...provided,
          color: '#495057',
          backgroundColor: '#e9ecef',
          border: '1px solid #c1c1c1'
        };
      }
      else return { ...provided }
    },
    singleValue: (provided, state) => {
      if (state.isDisabled) {
        return {
          ...provided,
          color: '#495057'
        };
      }
      else return { ...provided }
    }
  };

  useEffect(() => {
    (async function () {
      await termsRequest(setTermsTitle, setTermsText, setCanShowModal);
      await getMunicipalities(setMunicipalities);
      await getCountries(setCountries);
    })();
  }, []);

  useEffect(() => {
    if(form.country) {
      (async ()=>{
       await getStates(setStates, form.country);
      })();
    }
  }, [form.country]);

  useEffect(() => {
    if(form.state) {
      (async ()=>{
        await getCities(setCities, form.state);
      })(); 
    }
  }, [form.state]);

  const handleChangeForm = event => {    
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
    Object.keys(errors).forEach((error) => {
      if (error === event.target.name) {
        delete errors[event.target.name];
      }
    });
  };

  const onSubmitFormHandler = async event => {
    event.preventDefault();
    Loader.show(true);
    const registrationResponse = await register(form, recaptcha, setErrors);
    Loader.show(false);
    if (!registrationResponse) {
      return
    }
    setTextAlertRegistrationModal(registrationResponse);
    setIsRegistrationModalShowing(true);
  };

  const onAccetRegistrationModal = () => {
    setIsRegistrationModalShowing(false);
    props.history.push("/");
  }

  const ModalToTermsAndConditions = ModalTermsAndConditions(
    handleTermsModal,
    setHandleTermsModal,
    termsText,
    termsTitle
  );

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={backgroundImageStyles}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h3 className="mb-0">Crear Cuenta</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }} className="pT-30">
                      <p className="mB-30">
                        Para registrarte, por favor completa el siguiente
                        formulario.
                      </p>

                      <Form onSubmit={onSubmitFormHandler} className="mB-20">
                        <Form.Group>
                          <TextTooltip text={'Selecciona el régimen fiscal bajo el cual realizarás tu registro de usuario. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Regimen Fiscal
                            </Form.Label>
                          </TextTooltip>

                          <Form.Control
                            name="regime"
                            onChange={handleChangeForm}
                            as="select"
                            required
                            defaultValue={''}
                          >
                            <option value={''} disabled>Regimen Fiscal...</option>
                            <option value="1">Persona Fisica</option>
                            <option value="2">Persona Moral</option>
                          </Form.Control>

                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoElectronico">
                          <TextTooltip text={' Ingresa el correo electrónico que será utilizado para la confirmación de tu cuenta. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Correo
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="email"
                            placeholder="Correo Electronico"
                            required
                            name="email"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            className={renderClassesToErrors(errors, "email")}
                          />
                          {renderErrorsList(errors, "email")}
                        </Form.Group>

                        <Form.Group controlId="formBasicGender">
                          <TextTooltip text={'Selecciona tu género. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Género
                            </Form.Label>
                          </TextTooltip>

                          <Form.Control
                            name="gender"
                            onChange={handleChangeForm}
                            as="select"
                            required
                            defaultValue=""
                          >
                            <option value="" disabled>Selecciona tu género...</option>
                            {
                              genders.map((g, i) => (
                                <option value={g.value} key={i}>
                                  {g.label}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {renderErrorsList(errors, "gender")}
                        </Form.Group>

                        <Form.Group controlId="formBasicAge">
                          <TextTooltip text={'Ingresa tu edad con caracteres numéricos. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Edad
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="number"
                            placeholder="Ingresa tu edad"
                            required
                            name="age"
                            onChange={handleChangeForm}
                            autoComplete="off"
                            onInput={(e) => {
                              if (e.target.value < 1) e.target.value = ""
                              else e.target.value = Math.round(e.target.value);
                              e.target.value = e.target.value.slice(0,3);
                            }}
                            className={renderClassesToErrors(errors, "age")}
                          />
                          {renderErrorsList(errors, "age")}
                        </Form.Group>

                        <Form.Group controlId="formBasicBirthdate">
                          <TextTooltip text={' Selecciona tu fecha de nacimiento. Obligatorio.'}>
                          <Form.Label className="font-weight-bold">
                            * Fecha de nacimiento
                          </Form.Label>
                          <DatePicker
                            dateFormat='dd/MM/yyyy'
                            maxDate={moment().subtract(18, 'years')._d}
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled
                            }) => secondCustomHeaderDatePicker(
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled
                            )}
                            locale='es'
                            customInput={<CustomInput ref={firstInputDatePicker} />}
                            onChange={(selected) => handleChangeForm({
                              target: { 
                                name: "birthday", 
                                value: selected 
                              }
                            })}
                            selected={form["birthday"] ? moment(form["birthday"]).toDate() : ""}
                          />
                          <small style={{ fontSize:"-0.2rem" }}>
                            Debes tener mínimo 18 años para poder registrarte en el sistema
                          </small>
                          {renderErrorsList(errors, "birthday")}
                          </TextTooltip>
                        </Form.Group>

                        <Form.Group>
                          <h4><b>Domicilio</b></h4>
                          <hr style={{ marginTop:'-5px' }}/>
                        </Form.Group>

                        <Form.Group controlId="formBasicCountry">
                          <TextTooltip text={'Selecciona el país donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * País
                            </Form.Label>
                          </TextTooltip>
                          <Select
                            options={countries}
                            onChange={(selected) => {
                              handleChangeForm({target: {name: 'country', value: selected.value}})
                            }}
                            placeholder="Selecciona el pais donde vives..."
                            styles={selectStylesDefault}
                          />
                          {renderErrorsList(errors, "country")}
                        </Form.Group>
                        <Form.Group controlId="formBasicState">
                          <TextTooltip text={'Selecciona el estado donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Estado
                            </Form.Label>
                          </TextTooltip>
                          <Select
                            isDisabled={!form["country"]}
                            options={states}
                            onChange={(selected) => {
                              handleChangeForm({target: {name: 'state', value: selected.value}})
                              
                            }}
                            placeholder="Selecciona el estado donde vives..."
                            styles={selectStylesDefault}
                          />
                          {renderErrorsList(errors, "state")}
                        </Form.Group>
                        <Form.Group controlId="formBasicCity">
                          <TextTooltip text={'Selecciona la ciudad donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Ciudad
                            </Form.Label>
                          </TextTooltip>
                          <Select
                            isDisabled={!form["state"]}
                            options={cities}
                            onChange={ (selected) => {
                              handleChangeForm({target: {name: 'city', value: selected.value}})
                            }}
                            placeholder="Selecciona la ciudad donde vives..."
                            styles={selectStylesDefault}
                          />
                          {renderErrorsList(errors, "city")}
                        </Form.Group>

                        <Form.Group controlId="formBasicColony">
                          <TextTooltip text={'Ingresa el nombre de la colonia donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Colonia
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el nombre de la colonia donde vives"
                            name="colony"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "colony")}
                          />
                          {renderErrorsList(errors, "colony")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoStreet">
                          <TextTooltip text={'Ingresa la calle donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Calle
                            </Form.Label>
                          </TextTooltip>

                          <Form.Control
                            type="text"
                            placeholder="Ingresa la calle donde vives"
                            required
                            name="street"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "street")}
                          />

                          {renderErrorsList(errors, "street")}
                        </Form.Group>

                        <Form.Group controlId="formBasicNumExt">
                          <TextTooltip text={'Ingresa el número exterior de donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Número exterior
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el número exterior de donde vives"
                            required
                            name="ext_number"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "ext_number")}
                          />
                          {renderErrorsList(errors, "ext_number")}
                        </Form.Group>

                        <Form.Group controlId="formBasicNumInt">
                          <TextTooltip text={'Ingresa el número interior de donde vives'}>
                          <Form.Label className="font-weight-bold">
                            Número interior
                          </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="text"
                            placeholder="Ingresa el número interior de donde vives"
                            name="int_number"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "int_number")}
                          />
                          {renderErrorsList(errors, "int_number")}
                        </Form.Group>

                        <Form.Group controlId="formBasicZipCode">
                          <TextTooltip text={'Ingresa el código postal de donde vives. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Código postal
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="number"
                            placeholder="Ingresa el código postal de donde vives"
                            name="zip_code"
                            required
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "zip_code")}
                            pattern="\d{5}"
                            onInput={(e) => {
                              if (e.target.value < 1) e.target.value = ""
                              else e.target.value = Math.round(e.target.value);
                              e.target.value = e.target.value.slice(0,5);
                            }}
                          />
                          {renderErrorsList(errors, "zip_code")}
                        </Form.Group>
                        <hr/>

                        <Form.Group controlId="formBasicCorreoContraseña">
                          <TextTooltip text={'Crea una contraseña de mínimo 8 caracteres. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Contraseña
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="password"
                            placeholder="Contraseña"
                            required
                            name="password"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password")}
                          />
                          {renderErrorsList(errors, "password")}
                        </Form.Group>

                        <Form.Group controlId="formBasicCorreoConfirmarContraseña">
                          <TextTooltip text={'Confirmar contraseña. Obligatorio.'}>
                            <Form.Label className="font-weight-bold">
                              * Confirmar contraseña
                            </Form.Label>
                          </TextTooltip>
                          <Form.Control
                            type="password"
                            placeholder="Confirmar contraseña"
                            required
                            name="password_confirmation"
                            onChange={handleChangeForm}
                            className={renderClassesToErrors(errors, "password_confirmation")}
                          />
                          {renderErrorsList(errors, "password_confirmation")}
                        </Form.Group>

                        <div>
                          <Link to="/">Ya tengo cuenta</Link>
                        </div>
                        <div>
                          <p>
                            Al registrarme, aseguro haber leído y aceptado los
                            <span
                              onClick={canShowModal ? () => {
                                setHandleTermsModal(true);
                              } : null}
                              className="cursor-pointer text-primary px-1"
                            >
                              términos y condiciones
                            </span>
                            del uso del sistema.
                          </p>
                        </div>

                        <div className="overflow-auto w-100">
                          <GoogleRecaptcha
                            validate={value => setRecaptcha(value)}
                          />
                        </div>
                        {!recaptcha ? renderErrorsList(errors, "recaptcha") : null}
                        <GroupButtonsForm
                          primaryText="Registrarme"
                          secondaryText="Cancelar"
                        />
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <>
        { ModalToTermsAndConditions}
      </>
      <TextAlertModal
        headerText={'Alerta'}
        textAlert={textAlertRegistrationModal}
        isTextAlertModalShowing={isRegistrationModalShowing}
        hadleCloseTextAlertModal={onAccetRegistrationModal}
        hadleAcceptTextAlertModal={onAccetRegistrationModal}
      />
    </Plain>
  );
};

export default Register;
